// extracted by mini-css-extract-plugin
export var headerSubtitleBorder = "payload-module--header-subtitle-border--36bfc";
export var headerSubtitleContainer = "payload-module--header-subtitle-container--bc82b";
export var headerTitleContainer = "payload-module--header-title-container--eb1dc";
export var logosAndPartnersInnerWrapper = "payload-module--logos-and-partners-inner-wrapper--7fd23";
export var logosAndPartnersTitleStyles = "payload-module--logos-and-partners-title-styles--4023f";
export var meetCodilimeInnerWrapper = "payload-module--meet-codilime-inner-wrapper--8c111";
export var ourOfficesInnerWrapper = "payload-module--our-offices-inner-wrapper--10e86";
export var sectionWithPhotos = "payload-module--section-with-photos--0d177";
export var sectionWithPhotosDescription = "payload-module--section-with-photos-description--4df48";
export var sectionWithPhotosInnerWrapper = "payload-module--section-with-photos-inner-wrapper--41497";
export var sectionWithPhotosSubtitle = "payload-module--section-with-photos-subtitle--59698";
export var sliderWithClickableBlocksInnerWrapper = "payload-module--slider-with-clickable-blocks-inner-wrapper--8d9a6";
export var sliderWithClickableBlocksSection = "payload-module--slider-with-clickable-blocks-section--64f7f";
export var sliderWithClickableBlocksTitlesContainer = "payload-module--slider-with-clickable-blocks-titles-container--6a0b3";
export var sliderWithOurValuesInnerWrapper = "payload-module--slider-with-our-values-inner-wrapper--711b3";
export var testimonialsInnerWrapper = "payload-module--testimonials-inner-wrapper--c1033";